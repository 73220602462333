<template>
  <div class="content-wrapper">
    <div class="content-lookup">  <!-- 필터 -->
      <div class="lookup-left">
        <ul class="lookup-condition">
          <!-- 범위 날짜 검색 -->
          <li class="field">
            <div class="title"> 입고 일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range type="lookup-condition"  v-model="searchFilterOptions.wrhousngDate"/>
              </li>
            </ul>
          </li>
          <!--  여러개 드롭다운   -->
          <li class="field">
            <div class="title"> 입고 창고</div>
            <ul class="content">
              <li class="item">
                <ejs-multiselect ref="wrhous" v-model="searchFilterOptions.wrhous"
                                 :dataSource="commonCodeOptions.wrhousData" :fields="commonCodeOptions.fields"
                                 :allowFiltering="false" cssClass="lookup-condition-multiselect" width="250"/>
              </li>
            </ul>
          </li>
          <!-- 텍스트 한개   -->
          <li class="field">
            <div class="title"> 품명</div>
            <ul class="content">
              <li class="item">
                <input-text v-model="searchFilterOptions.suppliesName" @keydown.enter="onClickSearch"/>
              </li>
            </ul>
          </li>
          <!--    드롭다운      -->
          <li class="field account">
            <div class="title">거래처</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist ref="bcncMultiSelect" v-model="searchFilterOptions.bcncId"
                                  :dataSource="commonCodeOptions.bcncData"
                                  :fields="commonCodeOptions.bcncFields"
                                  :allowFiltering="true"
                                  filterType="Contains"
                                  cssClass="body-data-dropdown"/>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title"> 구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist ref="bcncMultiSelect" v-model="searchFilterOptions.wrhousngDiv"
                                  :dataSource="commonCodeOptions.wrhousngDiv"
                                  :fields="commonCodeOptions.fields" :allowFiltering="false"
                                  cssClass="body-data-dropdown"/>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button button-div="GET"  :ignore="isPopupOpened" :is-shortcut-button="true" @click.native="onClickSearch()">조회</erp-button>
        </div>
      </div>
      <div class="lookup-right"> <!-- 사용시 좌측이 없을 경우 생략-->
        <ul class="lookup-button"></ul>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article" :class="isOpen ? 'detail-view-opened' : ''">
        <div class="article-left">
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section class="article-section">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">{{ gridField.title }}</div>
                <div class="header-caption">[{{ gridField.count }}건]</div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <li class="add">
                    <erp-button button-div="GET" :is-icon-custom="true" @click.native="onClickAdd">추가</erp-button>
                  </li>
                  <li class="print">
                    <erp-button button-div="FILE" @click.native="onClickExcel">Excel</erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <div class="body-grid">
                <ejs-grid-wrapper
                    ref="grid"
                    v-bind="initGrid"
                    @actionComplete="gridComplete"
                    @recordClick="onGridClick"
                    @queryCellInfo="onQueryCellInfo"
                />
              </div>
            </div>
          </section>
        </div>
        <div class="article-right">
          <section class="article-section">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">입고 상세</div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <li class="save keyColor">
                    <erp-button button-div="SAVE" :is-key-color="true" :is-shortcut-button="true" :ignore="!isOpen || isPopupOpened" @click.native="onClickSave">저장</erp-button>
                  </li>
                  <li class="delete">
                    <erp-button v-if="!isNew" button-div="DELETE" :is-key-color="true" :is-shortcut-button="true" :ignore="!isOpen || isPopupOpened" @click.native="onClickDelete">삭제</erp-button>
                  </li>
                  <li class="close">
                    <erp-button button-div="CLOSE" @click.native="onClickClose">닫기</erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <div class="article-section" style="height: 140px;">
                <div class="body-data">
                  <div class="data-outer">
                    <div class="data-inner">
                      <ul class="data-content">
                        <!-- detail field -->
                        <li class="field">
                          <div class="title">
                            입고 번호
                          </div>
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group">
                                <div class="form">
                                  <input-text id="detailNum" ref="detailNum" v-model="detailData.wrhousngId" :readonly="true"/>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="field">
                          <div class="title required">
                            입고 일자
                          </div>
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group">
                                <div class="form">
                                  <input-date ref="wrhousngDate" type="lookup-condition"  :disabled="!isNew" v-model="detailData.wrhousngDate"/>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="field">
                          <div class="title required" >
                            입고 구분
                          </div>
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group">
                                <div class="form">
                                  <ejs-dropdownlist ref="wrhousngDiv" v-model="detailData.wrhousngDiv" :dataSource="commonCodeOptions.wrhousngDiv" :fields="commonCodeOptions.fields" cssClass="body-data-dropdown" :readonly="!isNew"/>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="field">
                          <div class="title required" >
                            입고 창고
                          </div>
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group">
                                <div class="form">
                                  <ejs-dropdownlist ref="wrhousCode" v-model="detailData.wrhousCode" :dataSource="commonCodeOptions.wrhousData" :fields="commonCodeOptions.fields" cssClass="body-data-dropdown" :readonly="!isNew"/>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="field voc-section">
                          <div class="title required">
                            거래처
                          </div>
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group">
                                <div class="form">
                                  <ejs-dropdownlist ref="bcncId" v-model="detailData.bcncId" :dataSource="commonCodeOptions.bcncData" :fields="commonCodeOptions.bcncFields" :allowFiltering="true" filterType="Contains" cssClass="body-data-dropdown"/>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="field voc-section">
                          <div class="title">
                            비고
                          </div>
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group">
                                <div class="form">
                                  <input-text ref="remarks" v-model="detailData.remarks"/>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="field">
                          <div class="title">
                            등록일자
                          </div>
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group">
                                <div class="form">
                                  <input-text ref="insertDt" v-model="detailData.insertDt" :readonly="true"/>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="field">
                          <div class="title">
                            등록자
                          </div>
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group">
                                <div class="form">
                                  <input-text ref="insertName" v-model="detailData.insertName" :readonly="true"/>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="article-section" style="height:calc(100% - 140px)">
                <section class="article-section">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">{{ gridField2.title }}</div>
                      <div class="header-caption">[{{ gridField2.count }}건]</div>
                      <div class="header-caption"> - {{ priceNotice }}</div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li class="add">
                          <erp-button button-div="GET"  @click.native="onClickAddSupplies">행추가</erp-button>
                        </li>
                        <li class="delete">
                          <erp-button button-div="DELETE" @click.native="onClickDelSupplies">행삭제</erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid table-side">
                      <ejs-grid-wrapper ref="grid2" v-bind="initGrid2"
                                        @cellSaved="onCellSaved2"
                                        @headerCellInfo = "onHeaderCellInfo2"
                                        @queryCellInfo="onQueryCellInfo2"
                                        @actionComplete="gridComplete2"/>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>
      </article>

    </div>
    <!-- 각종팝업 -->
    <supplies-search-popup
        ref="suppliesSearchPopup"
        v-if="isShowSuppliesPopup"
        @popupConfirmed="onPopupConfirmed"
    />
  </div>
</template>
<script>

import inputDateRange from "@/components/common/datetime/InputDateRange.vue";
import inputText from "@/components/common/text/InputText.vue";
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import messagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import moment from "moment";
import {getProductList} from "@/api/product";
import {commonCodesGetColorValue, commonCodesGetCommonCode, commonCodesGetStandardInfo} from "@/utils/commonCodes";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {Aggregate, Edit, ExcelExport, Filter, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import inputDate from "@/components/common/datetime/InputDate.vue";
import StockSuppliesMng from "@/api/v2/StockManage/StockSuppliesMng";
import {compareDate, getFormattedDate, getTodayDateTime} from "@/utils/date";
import {mapGetters} from "vuex";
import {validateFormRefs} from "@/utils/formUtil";
import suppliesSearchPopup from "@/views/stock-management/common/SuppliesSearchPopup.vue";
import StockCommon from "@/api/v2/StockManage/StockCommon";
import {Query} from "@syncfusion/ej2-data";


export default {
  name: "SuppliesWrhousngRegistration",
  mixins: [commonMixin, messagePopupDialogMixin],
  components: {suppliesSearchPopup, inputDate, ejsGridWrapper, ErpButton, inputText, inputDateRange},

  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      //부가세별도 여부
      excludingVat: commonCodesGetStandardInfo("suppliesVatSpuse"),
      //new edit start
      isOpen: false,
      isShowSuppliesPopup:false,
      searchFilterOptions: {
        wrhousngDate: {
          //from: getAddDays(new Date(),-30),
          from: moment().startOf('month').format('YYYY-MM-DD'),
          to: moment().format('YYYY-MM-DD'),
        },
        suppliesName: null,
        wrhous: [],
        bcncId: null,
        wrhousngDiv: null,
      },
      //드롭다운 코드
      commonCodeOptions: {
        bcncData: [],
        bcncFields: {text: "bcncName", value: "bcncId"},
        wrhousData: [],
        wrhousngDiv: commonCodesGetCommonCode("WRHOUSNG_DIV",false,true,"",""),
        fields: {text: "comName", value: "comCode"},
        //공통코드 들을 받아오는 필드
      },

      gridField: {
        title: "입고 목록",
        count: 0,
        dataSource: [],
      },
      //detail
      detailData: {
        wrhousngId: null,
        wrhousngDate: null,
        wrhousngDiv: "W1",

        wrhousCode: null,
        bcncId: null,
        remarks: null,
        delFlag: false,

        insertId: null,
        insertName: null,
        updateId: "",
        updateDt: "",
        dtlListD:[],
        dtlListU:[],
        dtlListI:[],
      },
      //validate 체크 항목
      validateRefField: {
        // wrhousngDate: { required: true },
        wrhousngDate: {required: true,},
        wrhousngDiv: { required: true },
        wrhousCode: { required: true },
        bcncId: { required: true },

      },
      gridField2: {
        title: "입고 품목",
        count: 0,
        dataSource: [],
      },
    };
  },
  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed: {
    ...mapGetters(["userIp","userId","username"]),
    isPopupOpened() {
      return (this.isShowSuppliesPopup);
    },
    isNew(){
      return (this.detailData.wrhousngId===null);
    },
    priceNotice(){
      return  this.excludingVat ? "부가세별도 단가": "부가세 포함단가";
    },
    initGrid() {
      return {
        columns: [
          {field: 'wrhousngId',    textAlign: 'center', width: 100, visible: true,   allowEditing : false,  type: 'number', headerText: '입고번호',},
          {field: 'wrhousngDiv',   textAlign: 'center', width: 100, visible: true,   allowEditing : false,  type: 'string', headerText: '입고구분', isCommonCodeField: true, dataSource: this.commonCodeOptions.wrhousngDiv,},
          {field: 'wrhousngDate',  textAlign: 'center', width: 100, visible: true,   allowEditing : false,  type: 'string', headerText: '입고일자',
            valueAccessor: (field,data) => getFormattedDate(data[field]) },
          {field: 'bcncId',        textAlign: 'center', width: 100, visible: true,   allowEditing : false,  type: 'string', headerText: '거래처', foreignKeyField: "bcncId", foreignKeyValue: "bcncName", dataSource: this.commonCodeOptions.bcncData,},
          {field: 'wrhousCode',    textAlign: 'center', width: 100, visible: true,   allowEditing : false,  type: 'string', headerText: '입고창고', isCommonCodeField: true, dataSource: this.commonCodeOptions.wrhousData,},
          {field: 'qty',           textAlign: 'right',  width: 100, visible: true,   allowEditing : false,  type: 'number', headerText: '품목건수', isNumericType: true,  },
          {field: 'netAmt',        textAlign: 'right',  width: 100, visible: true,   allowEditing : false,  type: 'number', headerText: '공급가', isNumericType: true,},
          {field: 'vatAmt',        textAlign: 'right',  width: 100, visible: true,   allowEditing : false,  type: 'number', headerText: '부가세', isNumericType: true,},
          {field: 'totAmt',        textAlign: 'right',  width: 100, visible: true,   allowEditing : false,  type: 'number', headerText: '입고금액', isNumericType: true,},
          {field: 'remarks',       textAlign: 'left',   width: 150, visible: true,   allowEditing : false,  type: 'string', headerText: '비고'},
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay: true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: true,
        pageSettings: {pageSize: 50},
        resizeSettings: {mode: "Normal"},

      };
    },
    initGrid2() {
      return {
        columns: [
          {field: 'suppliesCode', textAlign: 'center', width: 60, visible: true,   allowEditing : false, type: 'number', headerText: '저장품코드', isPrimaryKey: true},
          {field: 'suppliesName', textAlign: 'center', width: 50, visible: true,   allowEditing : false, type: 'string', headerText: '저장품명', },
          {field: 'unit',         textAlign: 'center', width: 50, visible: true,   allowEditing : false, type: 'string', headerText: '단위',  },
          {field: 'price',        textAlign: 'right',  width: 70, visible: true,   allowEditing : true, type: 'number', headerText: '단가', isNumericType: true,},
          {field: 'qty',          textAlign: 'right',  width: 40, visible: true,   allowEditing : true, type: 'number', headerText: '수량' ,
           isNumericType: true,  inputNumberProperty: {allowDot:true ,maxUnderDotLength: 3 },}, // editType:'numericedit', format: 'N2', // 그리드에서 버튼으로 숫자 조정시 세팅
          /*{field: 'qty',          textAlign: 'right',  width: 40, visible: true,   allowEditing : true, type: 'number', headerText: '수량' , isNumericType: true,}, // editType:'numericedit', format: 'N2', // 그리드에서 버튼으로 숫자 조정시 세팅*/
          {field: 'netAmt',       textAlign: 'right',  width: 70, visible: true,   allowEditing : false, type: 'number', headerText: '공급가',  isNumericType: true,},
          {field: 'vatAmt',       textAlign: 'right',  width: 70, visible: true,   allowEditing : false, type: 'number', headerText: '부가세',  isNumericType: true,},
          {field: 'totAmt',       textAlign: 'right',  width: 70, visible: true,   allowEditing : true, type: 'number', headerText: '입고금액',
            isNumericType: true,  inputNumberProperty: {allowDot:true ,maxUnderDotLength: 3 },},
        ],
        aggregates:[
          {
            columns: [
              {field: 'suppliesName', aggregationType: 'TotalCaption', customAggregate: '합계',},
              {field: 'qty',          aggregationType: 'TotalSum',},
              {field: 'netAmt',       aggregationType: 'TotalSum',},
              {field: 'vatAmt',       aggregationType: 'TotalSum',},
              {field: 'totAmt',       aggregationType: 'TotalSum',},
            ],
          },
        ],
        provides: [Aggregate,Filter, Resize, Page, ForeignKey, Edit,],
        dataSource: this.gridField2.dataSource,

        allowResizing: true,
        allowSorting: false,
        allowFiltering: false,
        allowExcelExport: false,
        isShowProgress: true,
        isNOColumnDisplay: false,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false,
        pageSettings: {pageSize: 50},
        resizeSettings: {mode: "Normal"},
        //그리드 정합성 체크
        validationRules: {
          suppliesCode:  {required: true, duplicateCheck: true},
          price:  {required: true,},
          qty:    {required: true, min:0.1},
          //qty:    {required: true, min:1},   //입고시는 1이상으로 하자.가격이 정수가 안나옴.
          totAmt: {required: true,},
        },
      };

    },
  },

  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
    // 기초 데이터 초기화
    this.clearDetailData();
  },
  async mounted() {
    //기초데이터 세팅
    await this.initData();
  },
  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
    validateFormRefs,
    //거래처 포함검색
    bcncFiltering(e) {
      // TODO : 공통 모듈화
      let searchData = this.commonCodeOptions.bcncData;
      if (e.text === "") {
        e.updateData(searchData);
      } else {
        let query = new Query().select(["bcncId", "bcncName"]);
        query =
            e.text !== ""
                ? query.where("bcncName", "contains", e.text, true)
                : query;
        e.updateData(searchData, query);
      }
    },
    //region******************************* API Call *******************************
    //기초데이터 세팅
    async initData() {
      // 입고일자 min,max 설정
      const datePickerInstance = this.$refs.wrhousngDate.$refs.datepicker.ej2Instances;
      //datePickerInstance.min = await StockCommon.getLastSuppliesClosingYm();// 최종 마감일자
      datePickerInstance.max = getFormattedDate(new Date());
      //거래처
      let params = {
        isInit: true,
      };
      const {value} = await getProductList(params);
      this.commonCodeOptions.bcncData = value.bcncList;
      this.commonCodeOptions.bcncData.unshift({bcncName: "", bcncId: null});
      // 창고
      this.commonCodeOptions.wrhousData  = await StockCommon.getWrhous({useFlag:'Y',authoFlag:'Y',suppliesFlag:'Y'});
    },

    async apiGetWrhousngList() {
      const args = {
        wrhousngDateFrom: this.searchFilterOptions.wrhousngDate.from,
        wrhousngDateTo: this.searchFilterOptions.wrhousngDate.to,
        suppliesName: this.searchFilterOptions.suppliesName,
        wrhousList: this.searchFilterOptions.wrhous.join('|'),
        bcncId: this.searchFilterOptions.bcncId,
        wrhousngDiv: this.searchFilterOptions.wrhousngDiv,
      };
      // 조회조건에 있는 창고만.
      if (args.wrhousList === "") {
        args.wrhousList = this.commonCodeOptions.wrhousData.map(data => data.comCode).join('|');
      }
      this.gridField.dataSource = await StockSuppliesMng.getSuppliesWrhousng(args);
    },
    async apiGetWrhousngDetail(args) {
       this.detailData = await StockSuppliesMng.getSuppliesWrhousngDtl(args);
    },
    async apiGetWrhousngSupplies(args) {
       this.gridField2.dataSource = await StockSuppliesMng.getSuppliesWrhousngSupplies(args);
    },
    async apiSaveWrhousngSupplies() {
      if(this.isNew) {
        await StockSuppliesMng.postSuppliesWrhousngSupplies(this.detailData);
      }else{
        await StockSuppliesMng.patchSuppliesWrhousngSupplies(this.detailData);
      }
    },
    async apiDelWrhousngSupplies() {
       await StockSuppliesMng.deleteSuppliesWrhousngSupplies({deleteKey:this.detailData.wrhousngId, deleteDate:this.detailData.wrhousngDate});
    },
    //endregion

    //region******************************* 버튼 이벤트 *******************************
    onClickSearch(){
      this.onClickClose();
      this.apiGetWrhousngList();
    },
    onClickAdd(){
      this.isOpen = true;
      this.clearDetailData();
    },
    onClickExcel(){
      this.$refs.grid.excelExport({fileName: this.gridField.title+".xlsx"});
    },
    async onClickSave() {
      //1. 필수 입력 체크
      if (!this.validateFormRefs(this.validateRefField)) {
        return;
      }

      //입고 일자 체크
      if (compareDate(getFormattedDate(new Date()), this.detailData.wrhousngDate) < 0) {
        this.errorToast("입고일자는 미래일자로 등록할 수 없습니다.");
        this.$refs.wrhousngDate.focus();
        return;
      }

      //품목 건수 체크
      if(this.gridField2.count === 0){
        this.errorToast("입고할 품목을 1건 이상 등록하세요.");
        return;
      }

      //2. 그리드 필수 입력체크
      if (this.$refs.grid2.isGridModified() && !this.$refs.grid2.validate()) {
        return;
      }

      //3. 저장 처리
      const {addedRecords, changedRecords, deletedRecords,} = this.$refs.grid2.getBatchChanges();
      this.detailData.dtlListD = deletedRecords;
      this.detailData.dtlListU = changedRecords;
      this.detailData.dtlListI = addedRecords;

      await this.apiSaveWrhousngSupplies();

      //4. 메세지 호출
      this.infoToast(this.$t('main.popupMessage.saved'));
      this.onClickSearch();
    },
    async onClickDelete(){
      if (!(await this.confirm(this.$t('main.popupMessage.confirmDelete')))) {
        return;
      }
      await this.apiDelWrhousngSupplies();
      this.infoToast(this.$t('main.popupMessage.deleted'));
      this.onClickSearch();
    },
    onClickClose(){
      this.isOpen = false;
      this.clearDetailData();
    },
    onClickAddSupplies(){
      this.isShowSuppliesPopup = true;
    },
    onClickDelSupplies(){
      this.$refs["grid2"].deleteRecord();
      this.gridField2.count = this.$refs.grid2.getBatchCurrentViewRecords().length;
    },
    //endregion
    //region ******************************* 공통 function *******************************

    clearDetailData() {
      this.detailData = {
        wrhousngId: null,
        wrhousngDate: getFormattedDate(new Date()),
        wrhousngDiv: "W1",
        wrhousCode: null,
        bcncId: null,
        remarks: null,
        delFlag: false,
        insertId: null,
        insertDt: getTodayDateTime(new Date()),
        insertName: this.username,
        dtlListD: [],
        dtlListU: [],
        dtlListI: [],
      };

      this.gridField2.dataSource = [];
    },
    //endregion
    //region ******************************* 그리드 이벤트 *******************************
    gridComplete(){
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
    gridComplete2(){
      this.gridField2.count = this.$refs.grid2.getGridBatchCount();
    },
    onGridClick(args){
      this.clearDetailData();
      const {rowData} = args;
      this.isOpen = true;
      this.apiGetWrhousngDetail({wrhousngId:rowData.wrhousngId});
      this.apiGetWrhousngSupplies({wrhousngId:rowData.wrhousngId});

    },
    onHeaderCellInfo2(args){
      const {cell: {column:{field}}, node} = args;
      //필수 입력
      if(this.$refs.grid2.validationRules[field]?.required){
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    onQueryCellInfo(args){
      const {cell, data} = args;
      //반품행 색상적용
      cell.style.color = commonCodesGetColorValue("WRHOUSNG_DIV", data.wrhousngDiv);
      /*if(data.wrhousngDiv === "W2"){
        cell.style.color = commonCodesGetColorValue("WRHOUSNG_DIV", data.wrhousngDiv);
      }*/
    },
    onQueryCellInfo2(args){
      const {column:{field,allowEditing}, cell} = args;
      if(allowEditing && field){
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
    },
    //저장품 단가 수량 입력시 자동으로 부가세,공급가,입고금액 계산
    //입고금액 입력시 부가세,공급가 계산
    onCellSaved2(args){
      let data= args.rowData;
      let index= this.$refs.grid2.getRowIndexByPrimaryKey(data.suppliesCode);
      switch (args.columnName) {
        case "qty":
          data.qty = args.value;
          break;
        case "price":
            data.price = args.value;
          break;
        case "totAmt":
            data.totAmt = args.value;
          break;
      }
      //부가세 계산방식 구분 (true-부가세별도: 단가*수량 =공급가, 공급가*0.1= 부가세 /// false-부가세포함: 단가*수량 =입고금액, 입고금액/11= 부가세)
      //면세면 부가세 0 //taxDiv = NOTAX
      const dp = 0;   //decimal point
      switch (args.columnName) {
        case "qty":
        case "price":
          if(this.excludingVat) {
            data.netAmt = Number((data.qty * data.price).toFixed(dp));
            data.vatAmt = data.taxDiv === "NOTAX" ? 0 : Number((data.netAmt * 0.1).toFixed(dp));
            data.totAmt = Number((data.netAmt + data.vatAmt).toFixed(dp));
          }else{
            data.totAmt = Number((data.qty * data.price).toFixed(dp));
            data.vatAmt = data.taxDiv === "NOTAX" ? 0 : Number((data.totAmt / 11).toFixed(dp));
            data.netAmt = Number((data.totAmt - data.vatAmt).toFixed(dp));
          }
          break;
        case "totAmt":
          //토탈금액은 부가세 + 공급가이므로 부가세별도 라도 동일함
          data.vatAmt = data.taxDiv === "NOTAX" ? 0 : Number((data.totAmt / 11).toFixed(dp));
          data.netAmt = Number((data.totAmt - data.vatAmt).toFixed(dp));
          break;
      }
      this.$refs.grid2.updateRow(index,data);
    },


    //endregion
    //region******************************* 팝업 이벤트 *******************************
    onPopupConfirmed(args){
      args && args.forEach((record)=>{
        // if(this.$refs.grid2.getBatchCurrentViewRecords().findIndex(v => v.suppliesCode === record.suppliesCode)>=0) {
        //   return;
        // }
        //부가세 계산방식 구분 (true-부가세별도: 단가*수량 =공급가, 공급가*0.1= 부가세 /// false-부가세포함: 단가*수량 =입고금액, 입고금액/11= 부가세)
        //면세면 부가세 0 //taxDiv = NOTAX
        const dp = 0;   //decimal point
        const amtData ={};
        if(this.excludingVat){
          //수량이 1개로 넘어오므로 가격이 공급가
          amtData.netAmt = record.price;
          amtData.vatAmt = record.taxDiv === "NOTAX" ? 0 : Number((record.price * 0.1).toFixed(dp)) ;
          amtData.totAmt = Number((amtData.netAmt + amtData.vatAmt).toFixed(dp));
        }else{
          //수량이 1개로 넘어오므로 가격이 합산
          amtData.totAmt = record.price;
          amtData.vatAmt = record.taxDiv === "NOTAX" ? 0 : Number((record.price /11).toFixed(dp)) ;
          amtData.netAmt = Number((record.price - amtData.vatAmt).toFixed(dp));
        }
        this.$refs.grid2.addRecord({
          wrhousngId   : this.detailData.wrhousngId,
          suppliesCode : record.suppliesCode,
          suppliesName : record.suppliesName,
          price        : record.price,
          qty          : 1 , //record.qty,
          oldQty       : 0 , //record.qty,
          unit         : record.unit,
          taxDiv       : record.taxDiv,
          ...amtData
        });
      });
      this.isShowSuppliesPopup = false;
      //ROW- COUNT
      this.gridField2.count = this.$refs.grid2.getBatchCurrentViewRecords().length;
    }
    //endregion
  },

};

</script>
<!-- ##################################### CUSTOM STYLE ########################################* -->
<style scoped>
body .appContent .body-article .article-left,body .appContent .body-article .article-right{transition:all .3s ease-out}
body .appContent .body-article .article-left{width:100%;}
body .appContent .body-article .article-right{margin-right: calc(-50% - 0px)}
body .appContent .body-article.detail-view-opened .article-left{width:calc(50% - 0px)}
body .appContent .body-article.detail-view-opened .article-right{width:calc(50% - 0px); margin:0}
body .body-footer .section-header {
  display: block;
  position: relative;
  margin: 0px 0px;
}
.voc-section {
  width: 100% !important;
}

</style>