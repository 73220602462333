import GolfErpAPI from "@/api/v2/GolfErpAPI";
export const ROOT_PATH = '/stock-supplies-mng';

class StockSuppliesMng {
    /**저장품 입고반품 리스트 조회*/
    async getSuppliesWrhousng(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/list` ,{params} );
        return data;
    }
    /**저장품 입고반품 상세 조회*/
    async getSuppliesWrhousngDtl(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/detail` ,{params} );
        return data;
    }
    /**저장품 입고반품 상세 입고/반품 아이템 조회*/
    async getSuppliesWrhousngSupplies(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/supplies` ,{params} );
        return data;
    }
    /**저장품 입고반품 생성*/
    async postSuppliesWrhousngSupplies(params) {
        const {data} = await GolfErpAPI.http.post(`${ROOT_PATH}/supplies` ,params);
        return data;
    }
    /**저장품 입고반품 수정*/
    async patchSuppliesWrhousngSupplies(params) {
        const {data} = await GolfErpAPI.http.patch(`${ROOT_PATH}/supplies` ,params);
        return data;
    }
    /**저장품 입고반품 삭제*/
    async deleteSuppliesWrhousngSupplies(params) {
        const {data} = await GolfErpAPI.http.delete(`${ROOT_PATH}/supplies` ,{params} );
        return data;
    }
}

export default new StockSuppliesMng();